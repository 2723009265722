import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, TextField, FormControlLabel, FormControl, FormHelperText, IconButton, InputLabel, Select, MenuItem, Switch, OutlinedInput, InputAdornment } from '@mui/material';
import { z } from 'zod';
import { useFieldArray, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createProject, getDetailsById, updateById } from '../../../../services/projectService';

import { useToaster } from '../../../../providers/ToasterProvider';
import { MainContainer } from '../../../../components/MainContainer/MainContainer';
import { Container } from '../../../../components/Container/Container';
import RadiusButton from '../../../../components/RadiusButton';

import HOME from '../../../../assets/images/home.svg';
import PLUS from '../../../../assets/images/plus.svg';
import ACTION_DELETE from '../../../../assets/images/action-delete-white.svg';
import UPLOAD from '../../../../assets/images/upload.svg';
import ARROW_LEFT from '../../../../assets/images/back.svg';
import SUBMIT_ICON from '../../../../assets/images/save-white.svg';
import EYE_ICON from '../../../../assets/images/eye.svg';
import EYE_CLOSE_ICON from '../../../../assets/images/eye-close.svg'
import './ProjectDetails.scss';
import { SuperAdminMenu } from '../SuperAdminMenu/SuperAdminMenu';
import { LoadingButton } from '@mui/lab';
import patterns from '../../../../constant/patterns';

const contactSchema = z.object({
    mobile: z.string().regex(new RegExp('^[0-9]*$'), { message: 'Please enter valid contact number' }).length(10, { message: 'Please enter valid contact number' })
});


const validMimeTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']

export const ProjectDetails = () => {

    const { addNotification } = useToaster();
    const [projectDetails, setProjectDetails] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoError, setLogoError] = useState(false);
    const [active, setActive] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isCreatingProject, setIsCreatingProject] = useState(false);

    const projectSchema = z.object({
        projectName: z.string().min(1, { message: 'Project Name is required' }),
        customerName: z.string().min(1, { message: 'Customer Name is required' }),
        customerSPOC: z.string().min(1, { message: 'Customer SPOC Name is required' }),
        customerContactDetails: z.array(contactSchema).nonempty({ message: 'Customer Contact Details is required' }),
        projectDescription: z.string().min(1, { message: 'Project Description is required' }),
        username: projectDetails ? z.string().optional() : z.string().min(1, { message: 'User name is required' }).email(),
        password: z.string().optional(),
        examMode: z.enum(['WEB_BASED', 'VENUE_BASED']).default('WEB_BASED'),
    }).superRefine(({ password }, ctx) => {
        if ((projectDetails && password && password.length < 8) || (!projectDetails && password.length < 8)) {
            ctx.addIssue({
                code: 'custom',
                message: 'Password should be at least 8 characters long',
                path: ['password']
            });
        }   
        if ((projectDetails && password && !patterns.PASSWORD.test(password)) || (!projectDetails && !patterns.PASSWORD.test(password))) {
            ctx.addIssue({
                code: 'custom',
                message: 'Password must contain one uppercase character, one lowercase character, one digit and one special character',
                path: ['password']
            });
        }
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
        reset,
        control,
        getValues,
        setValue
    } = useForm({
        resolver: zodResolver(projectSchema),
        defaultValues: projectDetails ? {} : {
            customerContactDetails: [{ mobile: '' }]
        }
    });

    const { fields: contactFields } = useFieldArray({ control, name: "customerContactDetails" });
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const projectId = location?.state?.projectId;
        if (projectId) {
            const fetchProjectdetails = async () => {
                try {
                    const response = await getDetailsById(projectId);
                    const projectData = response.data;

                    setProjectDetails(projectData);
                    setValue('projectName', projectData.projectName);
                    setValue('customerName', projectData.customerName);
                    setValue('customerSPOC', projectData.customerSPOC);
                    setValue('projectDescription', projectData.projectDescription);
                    setValue('username', projectData.username);
                    setValue('password', projectData.password);
                    setValue('examMode', projectData.examMode);
                    setActive(projectData.isActive);

                    if (projectData.mobile.includes(',')) {
                        const mobileNumbers = projectData.mobile.split(',');
                        const fields = []
                        for (let i = 0; i < mobileNumbers.length; i++) {
                            fields.push({
                                mobile: mobileNumbers[i]
                            });
                        }
                        reset({ customerContactDetails: [...fields] })
                    } else {
                        setValue('customerContactDetails.0.mobile', projectData.mobile);
                    }
                    setLogo(projectData.logo);
                    // setLogo(`${process.env.REACT_APP_API_URL.replace('/api', '')}/${projectData.logo}`);

                } catch (error) {
                    console.log('There is an error while getting project details', error);
                }
            }
            fetchProjectdetails();
        }
    }, [location]);


    const onSubmit = async (data) => {
        const projectData = new FormData();
        projectData.append('projectName', data.projectName.trim());
        projectData.append('customerName', data.customerName.trim());
        projectData.append('customerSPOC', data.customerSPOC.trim());
        const mobile = data.customerContactDetails.map((i) => i.mobile).join(',')
        projectData.append('mobile', mobile);
        projectData.append('projectDescription', data.projectDescription.trim());
        projectData.append('examMode', data.examMode);
        projectData.append('isActive', active);

        if (projectDetails?.id) {
            setIsCreatingProject(true);
            if (!data.username) {
                data.username = projectDetails.username;
            }
            if (data.username && data.password) {
                projectData.append('username', data.username);
                projectData.append('password', data.password);
            }
            if (logo && typeof logo !== 'string') {
                projectData.append('file', logo);
            }

            try {
                const result = await updateById(projectDetails.id, projectData);
                if (result && result?.code === 'OK') {
                    addNotification('Project updated successfully.', 'success');
                    goToHome();
                }
            } catch (error) {
                const message = error?.response?.data?.message ?? 'Project creation failed';
                addNotification(message, 'danger');
            } finally {
                setIsCreatingProject(false);
            }

        } else {
            if (!logo) {
                setLogoError(true);
                return;
            }
            setIsCreatingProject(true);
            projectData.append('username', data.username);
            projectData.append('password', data.password);
            projectData.append('file', logo);
            try {
                const result = await createProject(projectData)
                if (result && result?.code === 'CREATED') {
                    addNotification('Project created successfully.', 'success');
                    goToHome();
                }
            } catch (error) {
                const message = error?.response?.data?.message ?? 'Project creation failed';
                addNotification(message, 'danger');
            } finally {
                setIsCreatingProject(false);
            }
        }

    }

    const onAddNewContactField = () => {
        const values = getValues();
        const newFields = [...values.customerContactDetails, { mobile: '' }]
        reset({ customerContactDetails: [...newFields] })
    }

    const onRemoveContactField = (index) => {
        const values = getValues();
        const newFields = [...values.customerContactDetails]
        if (newFields.length > 1) {
            newFields.splice(index, 1)
            reset({ customerContactDetails: [...newFields] });
        }
    }

    const onChangeLogoImage = (event) => {
        if (!event.target.files[0]) return;
        if (!validMimeTypes.includes(event.target.files[0].type)) return;

        setLogo(event.target.files[0]);
        setLogoError(false);
    }

    const handleActiveChange = () => {
        setActive(!active);
    }

    const goToHome = () => {
        navigate('/projects');
    }
    return (
        <MainContainer>
            <SuperAdminMenu />

            <Container>
                <div className='heading-wrapper'>
                    <div className='title'>
                        {projectDetails ? 'Edit Project' : 'Create Project'}
                    </div>
                    <div className='home-icon-wrapper' onClick={goToHome}>
                        <img src={HOME} alt='' />
                    </div>
                </div>

                <Box sx={{
                    width: 732
                }} className='create-project-container' component="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className='create-project-wrapper'>
                        <div className='input-wrapper'>
                            <TextField
                                variant="outlined"
                                label='Project Name*'
                                className='text-input'
                                id='projectName'
                                margin='normal'
                                fullWidth name='projectName'
                                {...register('projectName')}
                                error={Boolean(errors.projectName)}
                                helperText={errors.projectName?.message}
                                onBlur={() => trigger('projectName')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <TextField
                                className='text-input'
                                id='customerName'
                                margin='normal'
                                fullWidth name='customerName'
                                variant="outlined"
                                label='Customer Name*'
                                {...register('customerName')}
                                error={Boolean(errors.customerName)}
                                helperText={errors.customerName?.message}
                                onBlur={() => trigger('customerName')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                            />
                        </div>
                        <div className='input-wrapper'>
                            <TextField
                                className='text-input'
                                id='customerSPOC'
                                margin='normal'
                                fullWidth name='customerSPOC'
                                variant="outlined"
                                label='Customer SPOC*'
                                {...register('customerSPOC')}
                                error={Boolean(errors.customerSPOC)}
                                helperText={errors.customerSPOC?.message}
                                onBlur={() => trigger('customerSPOC')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                            />
                        </div>

                        {
                            contactFields.map((field, index) => {
                                return <div className='input-wrapper' key={index}>
                                    <TextField
                                        sx={{
                                            width: '100%'
                                        }}
                                        className='text-input'
                                        id='customerContactDetails'
                                        margin='normal'
                                        fullWidth name='customerContactDetails'
                                        variant="outlined"
                                        label='Customer Contact Details*'
                                        {...register(`customerContactDetails.${index}.mobile`)}
                                        error={Boolean(errors?.['customerContactDetails']?.[index]?.['mobile'])}
                                        helperText={errors?.['customerContactDetails']?.[index]?.['mobile']?.message}
                                        onBlur={() => trigger('customerContactDetails')}
                                        InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                                    />
                                    <IconButton sx={{
                                        width: 48,
                                        height: 48,
                                        border: '1px solid',
                                        borderColor: 'primary.main',
                                        borderRadius: 1,
                                        mt: 0.5,
                                        '&:hover': {
                                            bgcolor: 'primary.50'
                                        }
                                    }} color="primary" onClick={() => onRemoveContactField(index)} disableRipple>
                                        <img src={ACTION_DELETE} alt='' />
                                    </IconButton>
                                    {
                                        index === contactFields.length - 1 && <IconButton sx={{
                                            width: 48,
                                            height: 48,
                                            border: '1px solid',
                                            borderColor: 'primary.main',
                                            borderRadius: 1,
                                            mt: 0.5,
                                            '&:hover': {
                                                bgcolor: 'primary.50'
                                            }
                                        }} color="primary" onClick={onAddNewContactField} disableRipple>
                                            <img src={PLUS} alt='' />
                                        </IconButton>
                                    }
                                </div>
                            })
                        }

                        <div className='input-wrapper'>
                            <TextField
                                className='text-input'
                                id='projectDescription'
                                margin='normal'
                                fullWidth name='projectDescription'
                                variant="outlined"
                                label='Project Description*'
                                {...register('projectDescription')}
                                error={Boolean(errors.projectDescription)}
                                helperText={errors.projectDescription?.message}
                                onBlur={() => trigger('projectDescription')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                            />
                        </div>

                        <div className='input-wrapper'>
                            <TextField
                                className='text-input'
                                id='username'
                                margin='normal'
                                fullWidth name='username'
                                variant="outlined"
                                label='User Email*'
                                {...register('username')}
                                error={Boolean(errors.username)}
                                helperText={errors.username?.message}
                                onBlur={() => trigger('username')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                                disabled={projectDetails ? true : false}
                            />
                        </div>
                        <div className='input-wrapper' style={{ rowGap: '8px', flexDirection: 'column' }}>
                            <FormControl variant='outlined' className='text-input' sx={{ mb: 2 }}>
                                <InputLabel htmlFor="passwordLabel">Password</InputLabel>
                                <OutlinedInput
                                    id='passwordLabel'
                                    type={passwordVisible ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                edge="end"
                                            >
                                                <img className='password-eye' src={passwordVisible ? EYE_CLOSE_ICON : EYE_ICON} alt='' />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    name="password"
                                    {...register('password')}
                                    error={Boolean(errors.password)}
                                    onBlur={() => trigger('password')}
                                />
                                {errors?.password?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        {errors.password?.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            {/* <TextField
                                className='text-input'
                                id='password'
                                margin='normal'
                                fullWidth name='password'
                                variant="outlined"
                                type="password"
                                label='Password*'
                                {...register('password')}
                                error={Boolean(errors.password)}
                                helperText={errors.password?.message}
                                onBlur={() => trigger('password')}
                                InputLabelProps={projectDetails?.id ? { shrink: true } : {}}
                            /> */}
                            {projectDetails && <FormHelperText sx={{
                                color: 'primary.main'
                            }}>
                                Enter password only if you wish to update.
                            </FormHelperText>}
                        </div>

                        <div className='input-wrapper'>
                            <FormControl sx={{
                                width: 611
                            }}>
                                {/* shrink={projectDetails ? true : false} */}
                                <InputLabel id="examModeLabel">Exam Mode*</InputLabel>
                                <Select
                                    labelId="examModeLabel"
                                    id="examMode"
                                    label='Exam Mode*'
                                    margin='none'
                                    name='examMode'
                                    {...register('examMode')}
                                    error={Boolean(errors.examMode)}
                                    onBlur={() => trigger('examMode')}
                                    defaultValue='WEB_BASED'
                                >
                                    <MenuItem value='WEB_BASED'>Web based</MenuItem>
                                    <MenuItem value='VENUE_BASED'>Venue based</MenuItem>
                                </Select>
                                {errors.examMode?.message && (
                                    <FormHelperText sx={{ color: 'error.main' }}>
                                        Exam mode is required
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </div>

                        <div className='input-wrapper'>
                            <FormControlLabel control={
                                <Switch
                                    // {...register('active')}
                                    // onBlur={() => trigger('active')}
                                    checked={active}
                                    onChange={handleActiveChange}
                                />
                            } label="Active" />
                        </div>

                        <div className='upload-wrapper'>
                            <div className='label'>
                                Upload Logo<span className='star'>*</span>
                            </div>

                            <div className="image-wrapper">
                                {logo && (
                                    <div className='preview-wrapper'>
                                        <img className='logo-image' src={projectDetails?.logo && typeof logo === 'string' ? logo : URL.createObjectURL(logo)} alt='' />
                                    </div>
                                )}

                                <RadiusButton sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 500,
                                }} color='primary'
                                    variant='outlined'
                                    size='large'
                                    // disabled
                                    startIcon={<img src={UPLOAD} alt='' />} htmlFor='logoImage' component='label'>
                                    Upload Logo
                                </RadiusButton>
                                <input
                                    className='file-input'
                                    type="file"
                                    name="logoImage"
                                    id='logoImage'
                                    accept={validMimeTypes}
                                    onChange={onChangeLogoImage}
                                />
                            </div>

                            {logoError && <FormHelperText error>Logo image is required</FormHelperText>}
                        </div>

                    </div>

                    <div className='buttons'>
                        <RadiusButton sx={{
                            textTransform: 'uppercase',
                            fontWeight: 500,
                        }} color='grey'
                            variant='contained'
                            size='large'
                            startIcon={<img src={ARROW_LEFT} alt='' />}
                            onClick={goToHome}
                            disabled={isCreatingProject}
                        >
                            Back
                        </RadiusButton>
                        <LoadingButton sx={{
                            textTransform: 'uppercase',
                            borderRadius: 40
                        }} color='primary'
                            type="submit"
                            variant='contained'
                            size='large'
                            loading={isCreatingProject}
                        >
                            {projectDetails?.id ? 'Update' : 'Submit'}
                        </LoadingButton>
                    </div>
                </Box>

            </Container>

        </MainContainer >
    );
}
